import React from 'react'
import Button from './button'
import './field-select.scss'


export function ValueComponent(props) {
    return <Button {...props} extraClassName="field-select">
        <Value {...props} />
    </Button>


}


export function OptionComponent({option, ...props}) {
    return <div {...props}>
        <Value {...option} />
    </div>
}

export function Value({value, placeholder}) {
    if (value) {
        return value.split('.').map((p, i) => {
            return <span className="field-select__part" key={i}>{p}</span>
        })
    }
    if (placeholder) {
        return placeholder
    }
    return 'select field'
}