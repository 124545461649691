import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import bem from './utils/bem'
import genId from './utils/gen_id'
import AddButton from './components/add-button'
import Button from './components/button'
import CloseIcon from './components/close-icon'
import DoneIcon from './components/done-icon'
import Modal from './components/modal'
import {ObjectContext} from './components/object-store'
import {useField} from './components/object-store'
import {useFocusBlurSaveField} from './components/object-store'
import {useGet} from './components/object-store'
import {useObjectStore} from './components/object-store'
import {useObject} from './components/object-store'
import {useSave} from './components/object-store'
import Page from './components/page'
import get from './components/get'
import {NewProduct} from './components/producer-editor'
import ProducerSelect from './components/producer-select'
import {align} from './components/popup-menu'
import {useAlignOnScroll} from './components/popup-menu'
import Portal from './components/portal'
import useHash from './utils/use-hash'
import {getScore} from './utils/match-filter'
import useCloseOnClickOutside from './hooks/use-close-on-click-outside'

export default function ProductsNotesPage() {
    const {setHashParam, params} = useHash()
    const save = useSave()

    const [openProductSelect, setOpenProductSelect] = React.useState(false)
    const [producerId, setProducerId_] = React.useState(() => {
        return params['producer']
    })

    React.useEffect(() => console.log('producerId', producerId), [producerId])

    function setProducerId(producerId) {
        setHashParam('producer', producerId)
        setProducerId_(producerId)
    }

    function onSelect(object) {
        if (object.id) {
            setHashParam('products', [object.id, params.products].filter(i => i).join(','))
        }
        if (object.name) {
            object.id ||= genId()
            object.tags ||= []
            object.ratings ||= []
            object.producer_id = producerId
            setHashParam('products', [object.id, params.products].filter(i => i).join(','))
            save('product', object)
        }
        setOpenProductSelect(false)
    }

    const productsIds = React.useMemo(() => {
        if (!params.products) {
            return []
        }
        return params.products.split(',')
    }, [params.products])


    useGet('producer', producerId)

    return <Page top={<Button onClick={() => setHashParam('products', null)} ><DoneIcon color="white" /></Button>}>
        <div className="spacer padded">
            <ProducerSelect
                style={{flexGrow: 1}}
                value={producerId}
                onUpdate={setProducerId}
                alignOptions="match-width"
            />
            <AddButton
                disabled={!producerId}
                onClick={() => setOpenProductSelect(true)}
            />
        </div>
        <div className="padded spacer spacer--p spacer--vertical">
            {productsIds.map(id => {
                return <ProductNoteEditor
                    id={id}
                    key={id}
                />
            })}
        </div>
        <Modal
            open={openProductSelect}
            onRequestClose={() => setOpenProductSelect(false)}
            style={{width: '100vw', height: '100vh'}}
        >
            <div className="to-right padded">
                <Button onClick={() => setOpenProductSelect(false)} className="icon-button">
                    <CloseIcon className="icon-button" />
                </Button>
            </div>
            <ProductSelect
                producerId={producerId}
                onSelect={onSelect}
            />
        </Modal>
    </Page>
}


function ProductSelect({producerId, onSelect}) {
    const producer = useObject(producerId)
    const [query, setQuery] = React.useState('')
    const {state} = useObjectStore()

    const groupedProducts = React.useMemo(() => {
        if (!state) {return {}}
        if (!producer) {return {}}
        const products = {}
        producer.products.forEach(productId => {
            const product = state[productId]
            products[product._name] ||= {}
            products[product._name][product.year] = productId
        })
        return products
    }, [producer && producer.products, state])

    const names = React.useMemo(() => {
        return filterSort(Object.keys(groupedProducts), query)
    }, [groupedProducts, query])

    if (!producer) {return null}
    return <div style={{flexGrow: 1, flexShrink: 1, overflow: 'auto'}}>
        <div className="padded">
            <input
                type="text"
                placeholder="search..."
                className="w100"
                value={query}
                onChange    ={e => setQuery(e.target.value)}
            />
        </div>
        {names.map(name => {
            const vintages = groupedProducts[name]
            return <ProductS
                key={name}
                name={name}
                vintages={vintages}
                onSelect={onSelect}
            />
        })}
        {query ?
            <div className="padded">
                <NewProduct query={query} onClick={() => onSelect({name: query})} />
            </div>
            : null
        }
    </div>
}

function ProductS({name, vintages, onSelect}) {
    const [other, setOther] = React.useState('')
    return <div key={name}  className="padded">
        <h5>{name}</h5>
        <div className="spacer spacer--wrap">
            {Object.keys(vintages).map(v => {
                return <Button
                    key={v}
                    onClick={() => onSelect({id: vintages[v]})}
                >
                    {v}
                </Button>
            })}
            <input
                type="number"
                placeholder="other..."
                style={{flexGrow: 1, flexShrink: 1, width: 0}}
                value={other}
                name="other"
                onChange={e => setOther(e.target.value)}
            />
            <AddButton
                onClick={() => onSelect({name: name + ' ' + other})}
            />
        </div>
    </div>
}

function filterSort(names, query) {
    if (!query) {return names}
    const words = query.split(' ')
    const lowerQuery = query.toLowerCase()
    const lowerWords = lowerQuery.split(' ')

    const prepared = names
        .map(name => {
            const score = getScore(name, query, lowerQuery, words, lowerWords)
            return {name, score}
        })

    return prepared
        .filter(p => p.score !== 0)
        .map(p => p.name)
}

function ProductNoteEditor({id, ...props}) {
    useGet('product', id)
    return <ObjectContext model="product" id={id}>
        <ProductNoteEditor_ id={id} {...props} />
    </ObjectContext>
}

function ProductNoteEditor_({id}) {
    const [producer_name] = useField('producer_name')
    const nameProps = useFocusBlurSaveField('name')
    const noteProps = useFocusBlurSaveField('note')
    return <div className="card padded spacer spacer--vertical">
        <h5>{producer_name}</h5>
        <input
            {...nameProps}
        />
        <TextareaAutosize
            minRows={3}
            {...noteProps}
        />
        <div className="spacer">
            <Rating source="AG" productId={id}/>
            <Rating source="PW" productId={id}/>
        </div>
    </div>
}


function Rating({source, productId}) {
    const [open, setOpen] = React.useState(false)
    const ref = React.useRef()
    const sref = React.useRef()
    const {setToAlign} = useAlignOnScroll(ref.current, null, 'PopupRatingEditor')

    const [ratings] = useField('ratings')
    const {state} = useObjectStore()

    const current = get((ratings || []).map(id => get(state, id)).filter(r => r.source === source), 0)
    const [currentNew, setCurrentNew] = React.useState(current)


    useCloseOnClickOutside(open, () => setOpen(false), sref, ref)

    const scores = [85, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100]
    const save = useSave()

    function addRating(canonical_rating) {
        canonical_rating += ''
        if (current) {
            current.canonical_rating = canonical_rating
            save('product_rating', current)
        }
        else {
            const rating = {
                id: genId(),
                source,
                canonical_rating,
                product_id: productId,
            }
            setCurrentNew(rating)
            save('product_rating', rating)
        }
    }


    return <span
        className={bem("rating").m({source: source, clickable: true})}
        role="button"
        tabIndex={0}
        ref={ref}
        onClick={() => setOpen(true)}
    >
        <span className="rating__canonical">
            {source}
        </span>
        <span className="rating__normalized">
            {get(current, 'canonical_rating') || get(currentNew, 'canonical_rating') || '?'}
        </span>
        {open ?
            <Portal parent={ref.current}>
                <div
                    ref={element => {
                        sref.current = element
                        align(ref.current, element)
                        setToAlign(element)
                    }}
                    className="popup-select spacer spacer--wrap padded"
                >
                    {scores.map(s => {
                        return <Button key={s} onClick={() => addRating(s)}>
                            {s}
                        </Button>
                    })}
                </div>
            </Portal>
            : null
        }
    </span>
}
