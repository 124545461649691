import React from 'react'

import {InitContext} from './components/init-context'
import {ObjectStore} from './components/object-store'
import {SpinningContext} from './components/spinning-context'
import MapPage from './page-map'
import ProducersPage from './page-producers'
import ProductsPage from './page-products'
import useHash from './utils/use-hash'
import {RouterContext} from './utils/use-hash'
import LoginPage from './page-login'
import {ResourcesModal} from './components/resource'
import PageTemplates from './page-templates'
import PageSavedQueries from './page-saved-queries'
import SelectionShelf from './components/selection-shelf'
import {SelectionShelfContext} from './components/selection-shelf'
import PageTrash from './page-trash'
import PageMultiAdd from './page-multi-add'
import PageProductPreset from './page-product-preset'
import ProductsNotesPage from './page-products-notes'

import './app.scss'
import './sheets.scss'


const Router = React.memo(Router_)


export default function App() {
    return <RouterContext>
        <SpinningContext>
            <ObjectStore>
                <InitContext>
                    <SelectionShelfContext>
                        <Router />
                        <ResourcesModal />
                    </SelectionShelfContext>
                </InitContext>
            </ObjectStore>
        </SpinningContext>
    </RouterContext>
}

function Router_() {
    const {hash, setHash} = useHash()

    if (hash.startsWith('#login')) {
        return <LoginPage />
    }
    if (hash.startsWith('#products-notes')) {
        return <ProductsNotesPage hash={hash} setHash={setHash} />
    }
    if (hash.startsWith('#products')) {
        return <ProductsPage hash={hash} setHash={setHash} />
    }
    if (hash.startsWith('#producers')) {
        return <ProducersPage hash={hash} setHash={setHash} />
    }
    if (hash.startsWith('#templates')) {
        return <PageTemplates hash={hash} setHash={setHash} />
    }
    if (hash.startsWith('#queries')) {
        return <PageSavedQueries hash={hash} setHash={setHash} />
    }
    if (hash.startsWith('#trash')) {
        return <PageTrash hash={hash} setHash={setHash} />
    }
    if (hash.startsWith('#multiadd')) {
        return <PageMultiAdd hash={hash} setHash={setHash} />
    }
    if (hash.startsWith('#product-preset')) {
        return <PageProductPreset hash={hash} setHash={setHash} />
    }
    return <MapPage hash={hash} setHash={setHash} />
}


// document.body.style.height = window.innerHeight + 'px'
// document.body.style.width = window.innnerWidth + 'px'

// window.addEventListener('resize', function() {
//     document.body.style.height = window.innerHeight + 'px'
//     document.body.style.width = window.innerWidth + 'px'
// })

// setInterval(() => {
//     document.body.style.height = window.innerHeight + 'px'
//     document.body.style.width = window.innerWidth + 'px'
// }, 1000)

// // document.body.style.height = '100%'
// // document.body.style.width = '100%'
