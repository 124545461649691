import React from 'react'

import Portal from './portal'
import bm from '../utils/bm'
import './modal.scss'


export default function Modal({children, open, extraClassName, innerExtraClassName, onRequestClose, padded, transparent, style, ...props}) {
    const ref = React.useRef()
    const innerRef = React.useRef()
    const mouseDownRef = React.useRef(false)
    const animteRef = React.useRef(false)
    const prevRef = React.useRef()
    const prevInneRef = React.useRef()
    const sourceRectRef = React.useRef()
    const targetRectRef = React.useRef()

    React.useEffect(() => {
        if (!open) {return}
        window.document.body.classList.add('with-modal')
        ref.current.focus()
        return () => {
            window.document.body.classList.remove('with-modal')
        }
    }, [open])

    function handleMouseDown(event) {
        if (event.target === ref.current) {
            mouseDownRef.current = true
        }
    }

    function handleCloseOverlay(event) {
        event.stopPropagation()
        if (event.target === ref.current && mouseDownRef.current && onRequestClose) {
            onRequestClose(event)
        }
        mouseDownRef.current = false
    }

    React.useEffect(() => {
        return () => {
            const inner = prevInneRef.current
            const modal = prevRef.current
            const sourceRect = sourceRectRef.current
            const targetRect = targetRectRef.current
            if (!sourceRect || !targetRect || !inner || !modal) {return}
            document.body.appendChild(prevRef.current)
            setTimeout(() => {
                modal.style.opacity = 0
                inner.style.opacity = 0
                inner.style.transform = `scaleX(${sourceRect.width / targetRect.width}) scaleY(${sourceRect.height / targetRect.height})`
                inner.style.left = (sourceRect.left - (targetRect.width - sourceRect.width) / 2) + 'px'
                inner.style.top = (sourceRect.top - (targetRect.height - sourceRect.height) / 2) + 'px'
                inner.style.position = 'fixed'

                setTimeout(() => {
                    document.body.removeChild(prevRef.current)
                }, 300)
            }, 0)
        }
    }, [])

    function animate(modal, inner) {
        if (ref.current && innerRef.current) {
            return
        }
        if (!modal || !inner) {
            return
        }
        if (!window.mapkiModalTarget) {
            return
        }
        if (animteRef.current) {return}
        animteRef.current = true
        const source = window.mapkiModalTarget
        modal.style.opacity = 0
        inner.style.opacity = 0
        setTimeout(() => {
            modal.style.opacity = 0
            inner.style.opacity = 0

            const sourceRect = source.getBoundingClientRect()
            sourceRectRef.current = sourceRect
            window.mapkiModalTarget = null
            const targetRect = inner.getBoundingClientRect() // {top: 5, left: 0, width: window.innerWidth - 10, height: window.innerHeight}
            targetRectRef.current = targetRect
            inner.style.transform = `scaleX(${sourceRect.width / targetRect.width}) scaleY(${sourceRect.height / targetRect.height})`
            inner.style.left = (sourceRect.left - (targetRect.width - sourceRect.width) / 2) + 'px'
            inner.style.top = (sourceRect.top - (targetRect.height - sourceRect.height) / 2) + 'px'
            inner.style.position = 'fixed'
            setTimeout(() => {
                inner.style.transition = 'all 0.2s ease-in'
                modal.style.transition = 'all 0.2s ease-in'
                modal.style.opacity = 1
                inner.style.opacity = 1
                inner.style.top = targetRect.top + 'px'
                inner.style.left = targetRect.left + 'px'
                inner.style.width = targetRect.width + 'px'
                inner.style.height = targetRect.height + 'px'
                inner.style.transform = `scaleX(1) scaleY(1)`
            }, 0)
        }, 0)
    }

    function setModalRef(element) {
        prevRef.current = ref.current
        animate(element, innerRef.current)
        ref.current = element
    }

    function setInnerRef(element) {
        prevInneRef.current = innerRef.current
        animate(ref.current, element)
        innerRef.current = element
    }

    return <Portal>
        <div
            className={bm("modal", {open, closed: !open},
            extraClassName)}
            onClick={handleCloseOverlay}
            onMouseDown={handleMouseDown}
            ref={setModalRef}
        >
            <div className={bm("modal__inner", {transparent, padded}, innerExtraClassName)} {...props} ref={setInnerRef} style={style}>
                {children}
            </div>
        </div>
    </Portal>
}